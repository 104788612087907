<template>
  <!-- 快递公司分布 -->
  <div id="vehicleDistribution" class="chart scrollbar" :class="{
    [$style.large]: large
  }">
    <ol :class="{
      [$style.barChart]: true,
    }">
      <li v-for="(item, index) in vData" :key="index">
        <span :class="$style.bar" :style="`width: ${inited ? (item.value / item.max * (large ? 34 : 12.6)) : 0}rem`">{{item.label}}</span>
        <span :class="$style.value">{{data[item.label]}}</span>
      </li>
    </ol>
  </div>
</template>

<style module lang="scss">
.large {
  height: 15rem;
  overflow: auto;

  .barChart {
    li {
      margin: .6rem 0;
      line-height: 1rem;

      .bar {
        height: 1rem;
      }
    }
  }
}

.barChart {
  li {
    display: flex;
    margin: .4rem 0;
    align-items: center;
    font-size: .7rem;
    line-height: .8rem;
    color: #5961A6;

    .bar {
      margin-right: .5rem;
      padding: 0 .4rem;
      height: .8rem;
      width: 2.3rem;
      min-width: 2.3rem;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      transition: width .3s;
      background-image: linear-gradient(153deg, #F15887 0%, #FE9B86 100%);
      border-radius: .8rem;
    }

    .value {
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}
</style>

<script>
import { TweenLite } from 'gsap';

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    const data = {};
    const dataTemp = {};

    this.vData.forEach((item) => {
      data[item.label] = 0;
      dataTemp[item.label] = 0;
    })

    return {
      inited: false,
      data,
      dataTemp
    };
  },
  watch: {
    vData() {
      this.transformData();
    }
  },
  mounted() {
    setTimeout(() => {
      this.transformData();
    }, 20)
  },
  methods: {
    transformData() {
      const that = this;
      const { dataTemp, vData } = that;
      const _data = {};

      vData.forEach((item) => {
        const { label, value } = item;

        _data[label] = value - 0;
      })

      TweenLite.to(dataTemp, 0.3, {
        ..._data,
        onUpdate() {
          const data = {};

          Object.entries(dataTemp).forEach(([key, value]) => {
            data[key] = parseInt(value);
          });

          that.data = data;
          that.inited = true;
        }
      });
    }
  },
};
</script>